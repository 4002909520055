/* eslint-disable import/prefer-default-export */

import PropTypes from 'prop-types';

const SLAResolutionType = PropTypes.shape({
  estimatedResolutionDatetime: PropTypes.string,
  estimatedResolution: PropTypes.shape({
    clientIsNew: PropTypes.bool,
    receiverIsNew: PropTypes.bool,
    clientHasCreditLine: PropTypes.bool,
    sla: PropTypes.number,
  }),
});

export { SLAResolutionType };
