/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';
import { MoneyType } from './MoneyType';
import { CreditLineEvaluationIndicatorType } from './CreditLineEvaluationIndicatorType';

export const CreditLineEvaluationVariablesType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  debt: MoneyType.isRequired,
  avgMonthlyCessions: MoneyType.isRequired,
  avgInternalRate: PropTypes.number.isRequired,
  estimatedInterest: MoneyType.isRequired,
  rejectionsPercent: PropTypes.number.isRequired,
  fixedAssetsPurchasesLastYear: MoneyType.isRequired,
  fixedAssets: MoneyType.isRequired,
  ipc: PropTypes.number.isRequired,
  monetaryPolicyRate: PropTypes.number.isRequired,
  spread: PropTypes.number.isRequired,
  avgOperationTerm: PropTypes.number.isRequired,
  creditlineevaluationindicatorsSet: PropTypes.arrayOf(
    CreditLineEvaluationIndicatorType.isRequired,
  ),
});
