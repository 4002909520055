import { useMemo } from 'react';
import { formatMoney, formatMoneyWithSign } from '../helpers';
import { calculateCommission, calculateInvoiceAmounts } from '../helpers/invoice-amounts-calculation';

const useSimulationAmounts = (invoices, customCommission = null) => {
  const amounts = invoices.map((invoice) => calculateInvoiceAmounts({
    days: invoice.days,
    retentionRate: invoice.preoffer.retentionRate,
    monthlyRate: invoice.preoffer.monthlyRate,
    amountWithIva: invoice.amountWithIva,
  }));
  const totalAmount = useMemo(() => invoices.reduce(
    (prev, curr) => prev + curr.amountWithIva.amount,
    0,
  ), [invoices]);
  const totalPriceDifference = useMemo(() => amounts.reduce(
    (prev, curr) => prev + curr.priceDifference,
    0,
  ), [amounts]);
  const totalRetention = useMemo(() => amounts.reduce(
    (prev, curr) => prev + curr.retentionAmount,
    0,
  ), [amounts]);
  const advanceAmount = useMemo(() => amounts.reduce(
    (prev, curr) => prev + curr.amountMinusInterests,
    0,
  ), [amounts]);
  const avgRate = useMemo(() => {
    const avg = invoices.reduce(
      (prev, curr) => curr.preoffer.monthlyRate * curr.amountWithIva.amount + prev,
      0,
    );
    return avg / totalAmount;
  }, [invoices, totalAmount]);
  const commission = customCommission || invoices.length
    ? calculateCommission(invoices[0].company.currentCommissionRules, totalAmount) : 0;
  const summary = [
    { label: 'Cantidad de facturas', value: invoices.length, format: formatMoney },
    { label: 'Tasa', value: avgRate.toFixed(2) },
    { label: 'Monto total', value: totalAmount, format: formatMoneyWithSign },
    { label: 'Diferencia de precio', value: totalPriceDifference, format: formatMoneyWithSign },
    { label: 'Comisión', value: commission || 0, format: formatMoneyWithSign, editable: true },
    { label: 'Costos totales', value: totalPriceDifference, format: formatMoneyWithSign },
    { label: 'Monto retenido', value: totalRetention, format: formatMoneyWithSign },
    { label: 'Monto a depositar', value: advanceAmount - commission, format: formatMoneyWithSign },
  ];
  return summary;
};

export default useSimulationAmounts;
