import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { PUBLIC_MASTER_ENTITIES } from '../graphql';
import useDebounce from './useDebounce';

const useSearchPublicEntities = (search) => {
  const [globalFilter, setGlobalFilter] = useState(search);
  const debounce = useDebounce(setGlobalFilter, 800);
  useEffect(() => {
    debounce(search);
  }, [search]);
  const { data, loading, previousData } = useQuery(PUBLIC_MASTER_ENTITIES, {
    variables: {
      globalFilter,
    },
    skip: !globalFilter || globalFilter.length < 3,
  });
  const usableData = data || previousData;
  const entities = usableData?.publicMasterEntities || [];
  return [entities, loading];
};

export default useSearchPublicEntities;
