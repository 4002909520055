/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';
import { MoneyType } from './MoneyType';

export const CreditLineEvaluationIndicatorType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  legalRepresentativeDicom: PropTypes.bool.isRequired,
  creditorsQuantity: PropTypes.number.isRequired,
  internalLeverage: PropTypes.number.isRequired,
  debtMultiplier: PropTypes.number.isRequired,
  customersConcentration: PropTypes.number.isRequired,
  dicomRate: PropTypes.number.isRequired,
  estimatedFlow: MoneyType.isRequired,
  bankCreditline: MoneyType.isRequired,
  flowCreditline: MoneyType.isRequired,
  profitCreditline: MoneyType.isRequired,
});
